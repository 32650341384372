import * as React from 'react';
import Cookies from 'universal-cookie';
import { graphql } from 'gatsby';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import BackToTopScroll from '../../components/backToTopScroll/backToTopScroll';
import GlobalStyles from '../../styles/globalStyles';
import { HtmlSiteMapHeader } from '../../styles';
import {
  addTrailingSlash,
  isValidURLLanguage,
  validNodeLocale,
} from '../../utils';
import { Helmet } from 'react-helmet';
import TitleWithImage from '../../components/titleWithImage/titleWithImage';
import AOS from 'aos';
import 'aos/dist/aos.css';
import BannerImage from '../../images/banner-default.jpg';

const HTMLSiteMap = ({ data, location }) => {
  React.useLayoutEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const cookies = new Cookies();
  const date = new Date();

  const queryParams = new URLSearchParams(location?.search);
  const regionQueryParams = queryParams.get('region')?.toUpperCase();

  typeof window !== 'undefined' &&
    regionQueryParams &&
    window?.history?.replaceState({}, document?.title, location?.pathname);

  const updateAndSetLanguageCookie = (language) => {
    if (language && cookies.get('language') !== language) {
      cookies.set('language', language, {
        path: '/',
        expires: new Date(
          date.getFullYear() + 1,
          date.getMonth(),
          date.getDate()
        ),
      });
      return language;
    }
  };

  const urlLanguage = location?.pathname?.split('/')?.find((lang) => lang);

  const selectedLanguage =
    isValidURLLanguage(urlLanguage) &&
    validNodeLocale(urlLanguage) &&
    cookies.get('language') !== validNodeLocale(urlLanguage)
      ? updateAndSetLanguageCookie(validNodeLocale(urlLanguage))
      : cookies.get('language')
      ? cookies.get('language')
      : 'en-US';

  const regionLanguageFilteredData =
    data?.allContentfulUltramarCommercial?.edges?.filter(
      (edge) => edge?.node?.node_locale === selectedLanguage
    );

  const allSlugs = [
    ...regionLanguageFilteredData
      ?.filter((edge) => {
        if (
          selectedLanguage === 'fr-CA' &&
          edge?.node?.slug?.includes('/en/')
        ) {
          return false;
        }
        return edge?.node?.slug && edge;
      })
      .map((edge) => {
        return {
          slug: addTrailingSlash(edge?.node?.slug),
          title: edge?.node?.title,
        };
      }),
    ...data?.allContentfulLocation?.edges
      ?.filter((edge) => {
        return (
          edge?.node?.urlSlug &&
          edge?.node?.node_locale === selectedLanguage &&
          edge
        );
      })
      .map((edge) => {
        return {
          slug: addTrailingSlash(
            (selectedLanguage === 'fr-CA'
              ? '/fr/sites/details/'
              : '/en/locations/details/') + edge?.node?.urlSlug
          ),
          title:
            edge?.node?.pageTitle ??
            edge?.node?.pageTitleDetails ??
            edge?.node?.urlSlug,
        };
      }),
  ].sort((a, b) => a?.slug?.localeCompare(b?.slug));

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <title>Site Map</title>
        <link
          rel="alternate"
          href={addTrailingSlash(
            process.env.GATSBY_DOMAIN_URL + '/en/html-sitemap'
          )}
          hreflang="en-ca"
        ></link>
        <link
          rel="alternate"
          href={addTrailingSlash(
            process.env.GATSBY_DOMAIN_URL + '/fr/html-sitemap'
          )}
          hreflang="fr-ca"
        ></link>
      </Helmet>
      <Header
        englishSlug={'/en/html-sitemap'}
        frenchSlug={'/fr/html-sitemap'}
      />
      <TitleWithImage
        sectionData={{
          text: { text: 'Site Map' },
          image: { file: { url: BannerImage } },
        }}
      />
      <div style={{ paddingTop: '20px' }}>
        {allSlugs.map(({ slug, title }, index) => (
          <HtmlSiteMapHeader
            key={index}
            $leftSpace={slug?.split('/')?.length - 1}
          >
            <a href={slug}>{title ?? slug}</a>
          </HtmlSiteMapHeader>
        ))}
      </div>

      <Footer />
      <BackToTopScroll />
    </>
  );
};

export default HTMLSiteMap;

export const pageQuery = graphql`
  query HTMLSiteMapEnglishQuery {
    allContentfulUltramarCommercial(
      filter: { node_locale: { eq: "en-US" }, hasIndex: { ne: false } }
    ) {
      edges {
        node {
          node_locale
          slug
          title
          name
          hasIndex
        }
      }
    }
    allContentfulLocation(
      filter: {
        brand: { brand: { in: ["Ultramar", "Pipeline Commercial"] } }
        node_locale: { eq: "en-US" }
      }
    ) {
      edges {
        node {
          urlSlug
          node_locale
          pageTitle
          pageTitleDetails
        }
      }
    }
  }
`;
