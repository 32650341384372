import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const MainContainer = styled.div`
  display: block;
  position: relative;
  margin-top: 220px;
  @media (max-width: 1399px) {
    margin-top: 210px;
  }
  @media (max-width: 1199px) {
    margin-top: 310px;
  }
  @media (max-width: 991px) {
    margin-top: 0;
  }
`;

export const LeftCol = styled(Col)`
  height: 320px;
  line-height: 300px;
  padding: 0 100px 0 100px !important;
  text-align: center;
  background: linear-gradient(
    to bottom,
    rgba(0, 166, 232, 1) 0%,
    rgba(0, 83, 157, 1) 100%
  );
  @media (max-width: 1399px) {
    height: 250px;
    line-height: 230px;
  }
  @media (max-width: 1199px) {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  @media (max-width: 767px) {
    height: auto;
    padding: 30px 35px 50px 35px !important;
    line-height: 33px;
  }
`;

export const HeaderDiv = styled.div`
  display: inline-block;
  vertical-align: middle;
  line-height: 80px;
`;

export const Header = styled.h1`
  position: relative;
  margin: 0;
  padding: 0;
  font-family: 'MrAlex', sans-serif;
  font-size: 55px;
  line-height: 55px;
  text-transform: uppercase;
  color: #fff;
  @media (max-width: 1399px) {
    font-size: 50px;
    line-height: 50px;
  }
  @media (max-width: 1199px) {
    font-size: 45px;
    line-height: 45px;
  }
  @media (max-width: 991px) {
    font-size: 40px;
    line-height: 40px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 33px;
  }
  :after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -20px;
    width: 75%;
    height: 4px;
    margin-left: auto;
    margin-right: auto;
    background: #ffc905;
    border-radius: 4px;
  }
`;

export const RightCol = styled(Col)`
  height: 320px;
  border-left: 4px solid #fff;
  background-repeat: no-repeat;
  background-position: top center;
  background: url(${(props) => props.$background}) no-repeat center center;
  background-size: cover;
  @media (max-width: 1399px) {
    height: 250px;
  }
`;
